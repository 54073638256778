import { useContext } from "react";
import { GifContext } from "../providers/gifProvider";
import SubmitGif from "./SubmitGif";

export default function GifGrid() {

    const { gifs, vote, tip } = useContext(GifContext);

    if (!gifs) return <></>

    return <div className="gif-grid">
        {gifs.map(({ link, title, userAddress, votes }, index) => (
            <div className="gif-item" key={link}>
                {userAddress.toString()}
                <img src={link} alt={title} />
                <div className="gif-controls">
                    <div className="title-value">{title}</div>
                    <div className="votes-value">{votes.toNumber()} votes</div>
                    <button className="cta-button love-button" onClick={() => vote(index)}>❤️</button>
                    <button className="cta-button tip-button" onClick={() => tip(userAddress, 100000000)}>🪙</button>
                </div>
            </div>
        ))}
        <div className="gif-item">
            <SubmitGif />
        </div>
    </div>

}