import { useContext, useRef, useState } from "react";
import { GifContext } from "../providers/gifProvider";


export default function SubmitGif({ wallet }) {
    const address = useRef();
    const title = useRef();
    const { addGif } = useContext(GifContext);
    const [submitting, setSubmitting] = useState(false);

    async function submit(e) {
        e.preventDefault();
        if (address.current.value.length > 0 && title.current.value.length > 0) {
            await addGif(address.current.value, title.current.value);
            address.current.value = '';
            title.current.value = '';
            setSubmitting(false)
        }
    }

    if (!submitting) {
        return <button className="add-gif" onClick={() => setSubmitting(true)}>+</button>
    }

    return <div id="submit-gif">
        <button className="cancel" onClick={() => setSubmitting(false)}>❌</button>
        <form className="gif-submit-form" onSubmit={submit}>
            <input
                ref={address}
                type="text"
                placeholder="Gif Link"
            />
            <input
                ref={title}
                type="text"
                placeholder="Gif Title"
            />
            <button type="submit" className="cta-button submit-gif-button">
                Submit
            </button>
        </form>
    </div>

}