import { useContext, useEffect, useState } from "react";
import { WalletContext } from "../providers/walletProvider";

// const TEST_GIFS = [
//     'https://media0.giphy.com/media/8qIq6Pnrdtoj8JGNYB/giphy.gif?cid=ecf05e47gslnbxh8a9ynvi4venifihkb0g5u8bfuavjvlssh&rid=giphy.gif&ct=g',
//     'https://media3.giphy.com/media/N3NGMfgLLviTymncX1/giphy.gif?cid=ecf05e474v3chwjbzyw0ulshyzsldjybkkkxjgikcx6p03wv&rid=giphy.gif&ct=g',
//     'https://media4.giphy.com/media/VNyRiqgv3Rx6emR8zn/giphy.gif?cid=ecf05e4785yrw7gdcy2n71drx8x8a7ej8acxr6euyz0xxe6p&rid=giphy.gif&ct=g',
//     'https://media3.giphy.com/media/KbkwfyxrWdMllZI8Tc/giphy.gif?cid=ecf05e472tn0856ashmogui41wtij4bhad4zebhbrgqtdofi&rid=giphy.gif&ct=g',
// ]

export function useGifs() {

    const {
        walletAddress,
        getProvider,
        getProgram,
        baseAccount,
        SystemProgram,
        bigNumber,
    } = useContext(WalletContext);

    const [gifs, setGifs] = useState([]);
    const [programAvailable, setProgramAvailable] = useState(false);

    async function addGif(title, address) {

        console.log("create gif", title, address)

        try {
            const program = await getProgram();
            const provider = getProvider()

            await program.rpc.addGif(
                title,
                address,
                {
                    accounts: {
                        baseAccount: baseAccount.publicKey,
                        user: provider.wallet.publicKey,
                    }
                })

            console.log("added", title, address)

            const gifs = await getGifList();

            setGifs(gifs);
        } catch (error) {
            console.log(error)
        }
    }

    async function vote(index) {

        const program = await getProgram();
        const provider = getProvider()

        await program.rpc.vote(
            bigNumber(index),
            {
                accounts: {
                    baseAccount: baseAccount.publicKey,
                    user: provider.wallet.publicKey,
                }
            })

        const gifs = await getGifList();

        setGifs(gifs);
    }

    async function tip(userAddress, amount=1) {

        const program = await getProgram();
        const provider = getProvider()

        await program.rpc.tip(new bigNumber(amount), {
            accounts: {
              from: provider.wallet.publicKey,
              to: userAddress,
              systemProgram: SystemProgram.programId,
            }
          });

        const gifs = await getGifList();

        setGifs(gifs);
    }

    async function getGifList() {
        try {
            const program = await getProgram();
            const account = await program.account.baseAccount.fetch(baseAccount.publicKey);
            return account.gifs;

        } catch (error) {
            return [];
        }
    }

    async function createGifAccount() {
        const program = await getProgram();
        const provider = getProvider();

        await program.rpc.initialize({
            accounts: {
                baseAccount: baseAccount.publicKey,
                user: provider.wallet.publicKey,
                systemProgram: SystemProgram.programId,
            },
            signers: [baseAccount]
        });

        console.log("Created a new BaseAccount w/ address:", baseAccount.publicKey.toString())
        const gifs = await getGifList();
        setGifs(gifs);
    }

    useEffect(() => {
        async function requestGifs() {
            if (walletAddress) {
                try {
                    const program = await getProgram();
                    console.log(program)
                    setProgramAvailable(true);
                } catch (error) {
                    console.log(error)
                }

                const gifs = await getGifList();
                setGifs(gifs);
            } else {
                setProgramAvailable(false);
            }
        }
        requestGifs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletAddress])


    return {
        gifs,
        addGif,
        createGifAccount,
        vote,
        programAvailable,
        tip
    }

}