import { useContext } from 'react';
import './App.scss';
import GifGrid from './components/GifGrid';
import SubmitGif from './components/SubmitGif';
import Portal from "./components/Portal";

import { WalletContext } from './providers/walletProvider';
import { GifContext } from './providers/gifProvider';
import flip from './functions/flip';

function AnimatedHeader({ word, size = '' }) {
  return <h1 className={`bouncy header ${size}`}>{word.split("").map((l, i) => <span className={'bounce'} key={l + i} style={{ animationDelay: `${i / 9}s` }}>{l}</span>)}</h1>
}

function Header() {

  const { walletAddress, connectWallet, disconnectWallet } = useContext(WalletContext);

  return <div className="user-header">
    {!walletAddress && <div>
      <button className="cta-button connect-wallet-button" onClick={connectWallet}>Connect your wallet</button></div>}

    {walletAddress && <div>Connected: {walletAddress} <button className="disconnect-wallet-button" onClick={disconnectWallet}>❌</button></div>}
  </div >

}

function Dialog({ open, children }) {
  return <Portal pointerEvents={open}>
    <dialog open={open}>
      {children}
    </dialog>
  </Portal>
}


const App = () => {

  const { walletAddress } = useContext(WalletContext);
  const { programAvailable, createGifAccount } = useContext(GifContext);

  return (
    <div className="App">
      <Header />
      <Dialog open={walletAddress && !programAvailable}>
        <form method="dialog">
          <button className="cta-button submit-gif-button" onClick={createGifAccount}>
            Do One-Time Initialization For GIF Program Account
          </button>
        </form>
      </Dialog>
      <div className="centered-container">
          <AnimatedHeader word="metawaves" size={'big'} />
        </div>
      <div className="container">
        {walletAddress && programAvailable && <div className="connected-container">
          <GifGrid />
        </div>}
      </div>
    </div>
  );
};

export default App;
