import { useEffect, useState } from "react"
import { Connection, PublicKey, clusterApiUrl } from '@solana/web3.js';
import { Program, AnchorProvider, web3, BN } from '@project-serum/anchor';
//import IDL from "../../metawaves/target/idl/metawaves.json"
import kp from '../keypair.json'

const arr = Object.values(kp._keypair.secretKey)
const secret = new Uint8Array(arr)

const { SystemProgram, Keypair } = web3;
const baseAccount = Keypair.fromSecretKey(secret)
const programID = new PublicKey('CQMWmBhZZtPf1wAJaoqzFXVsVWNPCnRPNjaaWnsDqgQK');
const network = clusterApiUrl('devnet');

const opts = {
  preflightCommitment: "processed"
}

function bigNumber(value) {
    return new BN(value);
}

export function useSolana() {

    const [walletAddress, setWalletAddress] = useState(null);
    const [loading, setLoading] = useState();

    useEffect(()=>{

        if(walletAddress) {
            document.body.setAttribute('data-solana-wallet-connected', true);
        } else {
            document.body.removeAttribute('data-solana-wallet-connected');
        }

    },[walletAddress]);

    useEffect(()=>{

        if(loading) {
            document.body.setAttribute('data-contract-loading', true);
        } else {
            setTimeout(()=>{
                document.body.removeAttribute('data-contract-loading');
            }, 1000);
        }

    },[loading])

    async function connectPhantom() {
        setLoading(true);
        if (window?.solana?.isPhantom) {
            const { publicKey } = await window.solana.connect({ onlyIfTrusted: true });
            setWalletAddress(publicKey.toString());
            
        }
        setLoading(false);
    }

    async function connectWallet() {
        setLoading(true);
        const { solana } = window;

        if (solana) {
            const response = await solana.connect();
            setWalletAddress(response.publicKey.toString());
        }
        setLoading(false);
    }

    async function disconnectWallet() {
        setLoading(true);
        const { solana } = window;

        if (solana) {
            const response = await solana.disconnect();
            setWalletAddress(null);
        }
        setLoading(false);
    }

    function getProvider () {
        const connection = new Connection(network, opts.preflightCommitment);
        const provider = new AnchorProvider(
          connection, window.solana, opts.preflightCommitment,
        );
        return provider;
    }

    async function getProgram () {
        setLoading(true);
        // Get metadata about your solana program
        const idl = await Program.fetchIdl(programID, getProvider());
        // Create a program that you can call
        setLoading(false);
        return new Program(idl, programID, getProvider());
      };
      

    useEffect(() => {
        const onLoad = async () => {
            await connectPhantom();
        };
        window.addEventListener('load', onLoad);
        return () => window.removeEventListener('load', onLoad);
    }, []);

    return {
        connectPhantom,
        connectWallet,
        disconnectWallet,
        walletAddress,
        getProvider,
        getProgram,
        baseAccount,
        SystemProgram,
        bigNumber
    }


}